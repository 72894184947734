document.addEventListener('DOMContentLoaded', () => {
  const articles = document.querySelectorAll('.done-section-feed-article');
  articles.forEach((article) => {
    const arrow = article.querySelector('.arrow');
    const showMore = article.querySelector('.show-more');

    showMore.style.height = '0px';

    article
      .querySelector('.done-section-feed-article-info')
      .addEventListener('click', () => {
        const isOpen = showMore.style.height !== '0px';

        articles.forEach((otherArticle) => {
          if (otherArticle !== article) {
            otherArticle.querySelector('.show-more').style.height = '0px';
            otherArticle.querySelector('.arrow').classList.remove('open');
          }
        });

        showMore.style.height = isOpen ? '0px' : `${showMore.scrollHeight}px`;
        arrow.classList.toggle('open', !isOpen);
      });
  });
});

function showMoreArticles() {
  const trigger = document.querySelector('.done-section-feed-overflow-trigger');
  const container = document.querySelector(
    '.done-section-feed-overflow-container'
  );

  trigger.style.display = 'none';
  container.style.display = 'block';
}

function showLessArticles() {
  const trigger = document.querySelector('.done-section-feed-overflow-trigger');
  const container = document.querySelector(
    '.done-section-feed-overflow-container'
  );

  trigger.style.display = 'block';
  container.style.display = 'none';

  const sectionFeed = document.querySelector('.done-section-feed');
  sectionFeed.scrollIntoView({ block: 'center' });
}
